.container {
  display: inline-flex;
  align-items: center;

  > p {
    margin: 0;
  }
}

.info_button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.2em;
  padding: 0;
  font-size: 1.18em;
  cursor: pointer;
  background: unset;
  border: unset;
}
