@use 'theme/breakpoints';

.content {
  display: flex;
  align-items: center;

  > :first-child {
    flex-grow: 1;
    margin-top: 11px;
    margin-bottom: 10px;
  }
}

.ion_label {
  margin-top: 0;
  margin-bottom: 0;
}

.button {
  display: none;
  min-width: 4.5em;
  min-height: 3em;

  @include breakpoints.mq(sm) {
    display: block;
  }
}

.ion_item {
  > div {
    width: 100%;
  }
}

.card {
  isolation: isolate;

  .ion_item {
    --background: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));
    --inner-border-width: 0;
    --padding-start: var(--ion-safe-area-left, 0);
    --color: var(--ion-card-color, var(--ion-item-color, var(--ion-color-step-600, #666)));

    @include breakpoints.mq(xs, sm) {
      --inner-padding-end: var(--ion-safe-area-right, 0);
    }
  }

  // stylelint-disable-next-line selector-class-pattern
  :global(.item-options-ios.item-options-end) {
    border-bottom-width: 0;
  }

  .content {
    > :first-child {
      margin-top: 0;
      margin-bottom: 0;
      overflow: hidden;
    }
  }
}
