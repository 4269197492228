.form {
  width: 100%;
  max-width: 400px;
  margin: auto;

  .logo_container {
    display: flex;
    margin: 1em 1em 2.5em;

    img {
      width: 100%;
      max-width: 400px;
      height: 100%;
      max-height: 40vh;
      margin: auto;
    }
  }

  ion-item {
    margin: 0.5rem;
  }

  ion-note {
    &:global(.md) {
      margin: 0 0.75em;
    }

    &:global(.ios) {
      margin: 0 1.85em;
    }
  }

  ion-button {
    margin: 1rem 0.5rem 0.5rem;
  }
}
