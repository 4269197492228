.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 0.2rem;
  text-align: center;

  > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    h1 {
      margin: 0;
      font-size: 5rem;
    }

    p {
      margin: 0 0 4rem;
    }
  }
}
