.banner {
  height: 1.5rem;
  padding: 0.2em;
  font-size: 1rem;
  text-align: center;
  cursor: default;
}

.ion_app {
  transition: all 200ms;
}
