.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
}

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > * {
    flex-grow: 1;
  }
}
