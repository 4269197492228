ion-menu.menu {
  --max-width: 300px;

  @media (min-width: 992px) {
    max-width: var(--max-width);
  }

  ion-content,
  ion-content ion-item {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
  }

  ion-list {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding: 10px 0;

    hr {
      width: 100%;
    }
  }

  ion-note {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    color: var(--ion-color-medium-shade);
  }

  ion-item {
    &.selected {
      --color: var(--ion-color-primary);
    }
  }

  .brand_box,
  .user_box,
  .version_box {
    padding-left: var(--custom-padding-x, var(--padding-start, 0));
    padding-right: var(--custom-padding-x, var(--padding-end, 0));
  }

  .brand_box {
    ion-list-header,
    ion-note {
      margin-bottom: 8px;
    }

    ion-list-header {
      align-items: flex-end;
      min-height: 40px;
      padding: 0;
      font-size: 22px;
      font-weight: 600;
    }
  }

  .version_box {
    margin-top: auto;
  }

  &:global(.md) {
    ion-content {
      --custom-padding-x: 10px;
      --padding-start: 8px;
      --padding-end: 8px;
    }

    ion-item {
      border-radius: 4px;

      ion-icon {
        color: #616e7e;
      }

      &.selected {
        --background: rgba(var(--ion-color-primary-rgb), 0.14);

        ion-icon {
          color: var(--ion-color-primary);
        }
      }
    }

    hr {
      --ion-border-color: var(--ion-color-step-150);
    }
  }

  &:global(.ios) {
    ion-content {
      --custom-padding-x: 16px;
    }

    ion-item {
      --min-height: 50px;

      ion-icon {
        font-size: 24px;
        color: #73849a;
      }

      &.selected ion-icon {
        color: var(--ion-color-primary);
      }
    }
  }
}
