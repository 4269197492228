.item_title {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5em;
  row-gap: 0.25em;
  align-items: center;
  font-size: 1.2em;

  .text {
    white-space: normal;
  }
}

.item {
  display: grid;
  grid-template-areas: 'sell-price' 'by-price' 'quantity';
  grid-template-columns: 1fr;
  width: 100%;

  @media (min-width: 32em) {
    grid-template-areas: 'sell-price quantity' 'by-price quantity';
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 48em) {
    grid-template-areas: 'sell-price by-price quantity';
    grid-template-columns: 1fr 1fr 1fr;
  }

  p:nth-child(1) {
    grid-area: sell-price;
  }

  p:nth-child(2) {
    grid-area: by-price;
  }

  p:nth-child(3) {
    grid-area: quantity;
  }
}
